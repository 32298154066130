<template>
  <div class="wrapper">
    <div v-for="component in components" :key="component.id">
      <component
        :is="component.tag"
        v-bind="component.props"
        :class="component.class"
        >{{ component.content }}</component
      >
    </div>
    <div v-for="comp in comps" :key="comp.id">
      <component v-if="comp" :is="comp.label"></component>
    </div>
  </div>
</template>

<script>
import list from "@/components/Test/_globals";
export default {
  name: "TestPage",
  data() {
    return {
      components: [],
    };
  },
  computed: {
    comps() {
      return list;
    },
  },
  methods: {
    getData() {
      this.components = [
        {
          id: 1,
          tag: "button",
          props: {},
          class: "primary-button",
          content: "click",
        },
        {
          id: 2,
          tag: "input",
          props: {
            name: "test",
            value: "",
            placeholder: "placeholder text",
          },
          class: "input-text",
          content: null,
        },
      ];
    },
  },
  created() {
    this.getData();
  },
};
</script>
